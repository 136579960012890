<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import TransactionStats from "../dashboard/transaction-component/transaction-report";

import List from "./list";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    List,
    TransactionStats
  },
  data() {
    return {
      title: "Report",
      showModal: false,
      items: [
        {
          text: "Agenda",
        },
        {
          text: "Report",
          active: true,
        },
      ],
    };
  },
  methods: {
    onDateChange(val) {
      console.log(val);
      this.$refs.stats.fetchData(val);
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <TransactionStats ref="stats"/>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <List ref="list" @onDateChange="onDateChange"/>
      </div>
    </div>
  </Layout>
</template>