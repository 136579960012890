<script>
/**
 * Widget Component
 */
import { reportService } from "../../../../helpers/backend/report.service";
export default {
  data() {
    return {
      statData: [
        {
          title: "Number of Sales",
          icon: "ri-stack-line",
          value: "1452",
          subvalue: " 2.4% ",
        },
        {
          title: "Sales Revenue",
          icon: "ri-store-2-line",
          value: "$ 38452",
          subvalue: " 2.4% ",
        },
        {
          title: "Average Price",
          icon: "ri-briefcase-4-line",
          value: "$ 15.4",
          subvalue: " 2.4% ",
        },
      ],
    };
  },
  mounted() {
    //this.fetchData();
  },
  methods: {
    fetchData(date) {
      reportService.getReportStats(date).then((data) => {
        if (data.report) {
          this.statData = [
            {
              title: "Daily Transaction",
              transaction_count: data.report.daily_transaction,
              transaction_gross : data.report.daily_gross,
            },
            {
              title: "Weekly Transaction",
              transaction_count: data.report.weekly_transaction,
              transaction_gross : data.report.weekly_gross,
            },
            {
              title: "Monthly Transaction",
              transaction_count: data.report.monthly_transaction,
              transaction_gross : data.report.monthly_gross,
            },
          ];
        }
      });
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-4" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
              <h4 class="mb-0">{{"Rp " +
                        (data.transaction_gross)
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  }}</h4>
            </div>
          </div>
        </div>

        <div class="card-body border-top py-3">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">Transaction count</p>
              <h4 class="mb-0">{{ data.transaction_count }} Transaction</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>