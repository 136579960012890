import { authHeader } from './auth-header';
import axiosInstance from "./client"

export const reportService = {
    getReportStats,
    getReportSummary,
    getReportItem,
};

function getReportStats(date) {
    const requestOptions = {
        url : '/api/v1/report/stats?report_date='+date,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}

function getReportSummary() {
    const requestOptions = {
        url : '/api/v1/report/summary?from_time=2021-03-01T00:00:00Z&to_time=2021-05-01T23:59:00Z',
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}

function getReportItem(date,type) {
    const requestOptions = {
        url : '/api/v1/report/fetch?report_date='+date+'&report_type='+type,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}
