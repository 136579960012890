<script>
import { reportService } from "../../../helpers/backend/report.service";
/**
 * Transactions component
 */
export default {
  data() {
    return {
      listData: [],
      loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "transaction_date", sortable: true, label: "Transaction Date" },
        { key: "total", sortable: true, label: "Total" },
        { key: "product_count", sortable: true, label: "Product Count" },
        { key: "vehicle_number", sortable: true, label: "Vehicle Number" },
      ],
      report_type: [
        { text: "Daily", value: "daily" },
        { text: "Weekly", value: "weekly" },
        { text: "Monthly", value: "monthly" },
      ],
      report_type_val: "daily",
      transaction_date: null,
      showDismissibleAlert: false,
      start_date_raw: null,
      end_date_raw: null,
      busy: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.listData.length;
    },
    report_date() {
      if (!this.transaction_date.includes("T")) {
        return this.transaction_date + "T00:00:00Z";
      }
      return this.transaction_date;
    },
    report_data_name() {
      return "Transaction_Report_" + this.report_type_val + "_" + new Date(this.transaction_date).toDateString() + ".csv"
    },
  },
  watch: {
    transaction_date: function () {
      if (!this.transaction_date.includes("T")) {
        this.$emit("onDateChange", this.transaction_date + "T00:00:00Z");
      } else {
        this.$emit("onDateChange", this.transaction_date);
      }
      this.fetchData();
    },
    report_type_val: function () {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.listData.length;
    this.transaction_date = new Date().toISOString();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    fetchData() {
      this.error = null;
      this.loading = true;
      this.showDismissibleAlert = false;
      //const fetchedId = this.$route.params.id
      this.busy = true;
      reportService.getReportItem(this.report_date, this.report_type_val).then(
        (data) => {
          this.listData = data.report;
          this.loading = false;
          this.busy = false;
        },
        (err) => {
          this.error =
            "Failed to fetch report data. Error : " +
            err +
            "  Dissmiss to refresh";
          this.loading = false;
          this.showDismissibleAlert = true;
          this.busy = false;
        }
      );
    },
    onDelete(id) {
      console.log(id);
      // if(confirm("Do you really want to delete this instance, this action can't be undone?")){
      //   customerService.deleteById(id).then(
      //     data => {
      //       this.fetchData()
      //       console.log(data)
      //     },
      //     err => {
      //       this.error = "Failed to delete customer. Error : " + err;
      //       this.showDismissibleAlert=true
      //     }
      //   )
      // }
    },
    onUpdate(id) {
      this.$emit("onEdit", id);
    },
    onShow(id) {
      this.$emit("onShow", id);
    },
  },
};
</script>
<template>
  <div>
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="fetchData"
    >
      {{ error }}
    </b-alert>
    <b-overlay :show="busy" rounded="lg" opacity="0.6">
      <div class="card">
        <div class="card-body">
          <!-- <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
        <template v-slot:button-content>
          <i class="mdi mdi-dots-vertical"></i>
        </template>
        <b-dropdown-item>Sales Report</b-dropdown-item>
        <b-dropdown-item>Export Report</b-dropdown-item>
        <b-dropdown-item>Profit</b-dropdown-item>
        <b-dropdown-item>Action</b-dropdown-item>
      </b-dropdown> -->

          <h4 class="card-title mb-4">Report</h4>
          <div class="row mt-4">
            <!-- <div class="col-sm-12 col-md-4">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div> -->

            <div class="col-sm-12 col-md-4">
              <b-form-group
                id="input-group-transaction-date"
                label="Transaction Date:"
                label-for="input-transaction-date"
              >
                <b-datepicker
                  id="input-transaction-date"
                  v-model="transaction_date"
                  required
                />
              </b-form-group>
            </div>
            <div class="col-sm-12 col-md-4">
              <b-form-group
                id="input-group-report-type"
                label="Report Type:"
                label-for="input-report-type"
              >
                <b-form-select
                  id="input-report-type"
                  v-model="report_type_val"
                  :options="report_type"
                  required
                />
              </b-form-group>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-4">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="row mt-4">
            <download-excel type="csv" :escapeCsv="false" :name="report_data_name" :data="listData">
              <b-button variant="light" style="padding: 10px; margin: 5px"
                >Download Data
                <i class="ri-download-2-line"></i>
              </b-button>
            </download-excel>
          </div>
          <div class="table-responsive">
            <b-table
              :items="listData"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(transaction_date)="row">
                <div>{{ new Date(row.value).toDateString() }}</div>
              </template>
              <template v-slot:cell(total)="row">
                <div>
                  {{
                    "Rp " +
                    row.value
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                  }}
                </div>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>